import { CopilotMessage } from "@/server/providers/copilot";
import { useState } from "react";
export const ThinkingChain = ({
  messages
}: {
  messages: CopilotMessage[];
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const lastUserMessage = messages.findLast(x => x.message_type === "user");
  const progress = lastUserMessage ? lastUserMessage?.metadata?.progress : null;
  const latestProgress = progress?.findLast(x => x.finished_at !== null);
  const latestProgressEvent = latestProgress?.event;
  const isLatestProgressEventStopEvent = latestProgressEvent === "[END-MARKER-MESSAGE-FROM-ONTRADE]";
  if (!latestProgressEvent || isLatestProgressEventStopEvent) {
    return null;
  }
  // filter out the stop event
  const filteredProgress = progress?.filter(p => p.event !== "[END-MARKER-MESSAGE-FROM-ONTRADE]");
  return <div className="flex flex-col gap-2 max-w-fit" data-sentry-component="ThinkingChain" data-sentry-source-file="ThinkingChain.tsx">
      {filteredProgress?.map((p, index) => <div key={p.event} className="flex flex-col">
          <ProgressDisplay progress={p.event} />
          {index !== filteredProgress.length - 1 && <div className="w-px h-4 bg-gray-300 ml-3 mt-[-2px]"></div>}
        </div>)}
    </div>;
};
const ProgressDisplay = ({
  progress
}: {
  progress: string;
}) => {
  return <div className="border border-gray-300 rounded-md p-2 max-w-fit" data-sentry-component="ProgressDisplay" data-sentry-source-file="ThinkingChain.tsx">
      <div className="text-gray-500 text-sm">{progress}</div>
    </div>;
};