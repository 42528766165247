import Link from "next/link";
import ReactMarkdown from "react-markdown";
import { AgentMessageChartingImage } from "./AgentMessageChartingImage";
import rehypeRaw from "rehype-raw";
// import remarkMath from "remark-math";
// import rehypeKatex from "rehype-katex";
import remarkGfm from "remark-gfm";
import { CopilotMessage } from "@/server/providers/copilot";
import Image from "next/image";
import dayjs from "dayjs";
import { cn } from "@/lib/utils";
import SuggestedPrompts from "./SuggestedPrompts";
export const AgentMessage = ({
  msg,
  inProgress
}: {
  msg: CopilotMessage;
  inProgress: boolean;
}) => {
  return <div className="w-full flex flex-row justify-start items-start p-2" data-sentry-component="AgentMessage" data-sentry-source-file="AgentMessage.tsx">
      <Image src="/agent-copilot.svg" alt="Agent Avatar" width={32} height={32} className={cn("mx-3", inProgress && "animate-spin")} data-sentry-element="Image" data-sentry-source-file="AgentMessage.tsx" />
      <div className="flex flex-col px-3 text-md prose dark:prose-invert">
        <ReactMarkdown components={{
        a: ({
          node,
          ...props
        }) => {
          return <Link href={props.href ?? ""} className="text-blue-600 underline hover:text-blue-800" target="_blank" rel="noopener noreferrer">
                  {props.children}
                </Link>;
        },
        img: ({
          node,
          ...props
        }) => <AgentMessageChartingImage metadata={msg.metadata} src={props.src ?? ""} />,
        table: ({
          node,
          ...props
        }) => <div className="overflow-x-auto w-full">
                <table {...props} className="min-w-full table-auto" />
              </div>
      }} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} data-sentry-element="ReactMarkdown" data-sentry-source-file="AgentMessage.tsx">
          {msg.content}
        </ReactMarkdown>
        <div className={cn("text-sm text-gray-500", msg.status !== "completed" && "hidden")}>
          {dayjs(msg.updatedAt).format("h:mm A")}
        </div>
        <SuggestedPrompts msgStatus={msg.status} followUpPrompts={msg.metadata?.follow_up_prompts} data-sentry-element="SuggestedPrompts" data-sentry-source-file="AgentMessage.tsx" />
      </div>
    </div>;
};