"use client";

import { trpc } from "@/providers/TrpcProvider";
import { useRouter, useSearchParams } from "next/navigation";
import { createContext, useContext, useEffect, useState } from "react";
import { CopilotMessage } from "@/server/providers/copilot";
type RouterConfig = {
  router: string;
  setRouter: (router: string) => void;
  routers: Array<string>;
};
type ChatContextType = {
  messages: CopilotMessage[];
  sendMessage: (query: string) => Promise<void>;
  currentlySending: boolean;
  lastIncompleteUserMessage: boolean;
  routerConfig: RouterConfig;
};
const ChatContext = createContext<ChatContextType>({} as ChatContextType);
export const useChat = () => {
  return useContext(ChatContext);
};
const routers = {
  multirouteRouter: "MultiRouteRouter",
  llmRouter: "LLMRouter",
  allRoutesRouter: "AllRoutesRouter",
  singleRouteRouter: "SingleRouteRouter"
};
export default function ChatProvider({
  conversationId,
  children,
  olderMessages = []
}: {
  olderMessages?: CopilotMessage[];
  conversationId?: string;
  children: React.ReactNode;
}) {
  const router = useRouter();
  const searchParams = useSearchParams();
  const [localMessages, setLocalMessages] = useState<CopilotMessage[]>(olderMessages);
  const {
    data: fetchedMessages = [],
    refetch: refetchMessages
  } = trpc.copilot.getMessages.useQuery({
    conversationId: conversationId!
  }, {
    enabled: !!conversationId,
    staleTime: 0,
    initialData: olderMessages
  });
  const localMessagesLength = localMessages.length;
  useEffect(() => {
    if (localMessagesLength <= fetchedMessages.length) {
      setLocalMessages(fetchedMessages);
    }
  }, [fetchedMessages]);
  const {
    mutateAsync: addMessage
  } = trpc.copilot.addMessage.useMutation();
  const lastUserMessage = localMessages.findLast(x => x.message_type === "user");
  const lastIncompleteUserMessage = lastUserMessage && lastUserMessage?.status !== "completed" && lastUserMessage?.status !== "failed";
  const [currentlySending, setCurrentlySending] = useState(false);
  const [workflowRouter, setWorkflowRouter] = useState<string>(routers.allRoutesRouter);
  const fetchUpdatedMessages = async () => {
    try {
      if (lastIncompleteUserMessage) {
        await refetchMessages();
        setCurrentlySending(false);
      }
    } catch (ignored) {
      // Ignore
    }
  };
  useEffect(() => {
    fetchUpdatedMessages();
  }, [lastIncompleteUserMessage, fetchedMessages, refetchMessages]);
  const sendMessage = async (query: string) => {
    if (query.trim() === "") return;
    try {
      setCurrentlySending(true);
      const optimisticMessage: CopilotMessage = {
        id: "optimistic-" + Date.now(),
        content: query,
        message_type: "user",
        status: "pending",
        createdAt: new Date(),
        role: "user",
        updatedAt: new Date(),
        metadata: {}
      };
      setLocalMessages(prev => [...prev, optimisticMessage]);
      const messageResult = await addMessage({
        message: query,
        conversationId: conversationId || undefined,
        router: workflowRouter
      });
      if (!conversationId) {
        const params = new URLSearchParams(searchParams);
        params.set("conversationId", messageResult.conversationId);
        router.push(`?${params.toString()}`);
      }
      setCurrentlySending(false);
    } catch (e) {
      setCurrentlySending(false);
    }
  };
  return <ChatContext.Provider value={{
    messages: localMessages,
    sendMessage,
    currentlySending,
    lastIncompleteUserMessage: lastIncompleteUserMessage || false,
    routerConfig: {
      router: workflowRouter,
      setRouter: setWorkflowRouter,
      routers: Object.values(routers)
    }
  }} data-sentry-element="unknown" data-sentry-component="ChatProvider" data-sentry-source-file="ChatProvider.tsx">
      {children}
    </ChatContext.Provider>;
}