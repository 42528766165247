import Image from "next/image";
export const NoMessagesState = () => {
  return <div className="flex flex-col items-center justify-center h-full" data-sentry-component="NoMessagesState" data-sentry-source-file="NoMessagesState.tsx">
      <div className="flex flex-row items-start justify-center">
        <Image src="/star-big.png" alt="Agent Avatar" width={200} height={200} data-sentry-element="Image" data-sentry-source-file="NoMessagesState.tsx" />
        <Image src="/star-small.png" alt="Agent Avatar" width={50} height={50} data-sentry-element="Image" data-sentry-source-file="NoMessagesState.tsx" />
      </div>
      <div className="text-center text-sm text-muted-foreground p-2">
        Ready to get answers? Ask your question below or explore popular prompts
        from other users.
      </div>
    </div>;
};