"use client";

import { CopilotMessage } from "@/server/providers/copilot";
import ChatProvider, { useChat } from "./ChatProvider";
import { UserMessage } from "./UserMessage";
import { AgentMessage } from "./AgentMessage";
import { ThinkingChain } from "./ThinkingChain";
import { ChatInputBox } from "./ChatInputBox";
import { ChatSendButton } from "./ChatSendButton";
import { useEffect, useRef } from "react";
import { NoMessagesState } from "./NoMessagesState";
import dayjs from "dayjs";
import { Select, SelectContent, SelectTrigger, SelectValue, SelectItem } from "@/components/ui/select";
const dummyAgentMessage: CopilotMessage = {
  id: "dummy-agent-message",
  message_type: "agent",
  content: "",
  createdAt: new Date(),
  updatedAt: new Date(),
  status: "pending",
  role: "assistant",
  metadata: {}
};

// Group messages by date
const groupMessagesByDate = (messages: CopilotMessage[]) => {
  const groups: {
    date: string;
    messages: CopilotMessage[];
  }[] = [];
  messages.forEach(message => {
    const messageDate = dayjs(message.updatedAt);
    const dateString = messageDate.format("dddd, MMMM D, YYYY ");
    const lastGroup = groups[groups.length - 1];
    if (lastGroup && lastGroup.date === dateString) {
      lastGroup.messages.push(message);
    } else {
      groups.push({
        date: dateString,
        messages: [message]
      });
    }
  });
  return groups;
};
export default function FullChat({
  conversationId,
  olderMessages
}: {
  conversationId?: string;
  olderMessages?: CopilotMessage[];
}) {
  return <ChatProvider conversationId={conversationId} olderMessages={olderMessages} data-sentry-element="ChatProvider" data-sentry-component="FullChat" data-sentry-source-file="CopilotChat.tsx">
      <Chat data-sentry-element="Chat" data-sentry-source-file="CopilotChat.tsx" />
    </ChatProvider>;
}
const Chat = () => {
  let inputElement: HTMLTextAreaElement | HTMLDivElement | null = null;
  const setInputRef = (element: HTMLTextAreaElement | HTMLDivElement | null) => {
    inputElement = element;
  };
  const clearInput = () => {
    if (inputElement) {
      if (inputElement instanceof HTMLTextAreaElement) {
        inputElement.value = ""; // Clear input field
      } else if (inputElement instanceof HTMLDivElement) {
        inputElement.innerText = ""; // Clear input field
      }
    }
  };
  const {
    messages,
    sendMessage,
    currentlySending,
    lastIncompleteUserMessage,
    routerConfig
  } = useChat();
  const handleSendMessageFromButton = () => {
    if (inputElement instanceof HTMLTextAreaElement) {
      sendMessage(inputElement.value);
    } else if (inputElement instanceof HTMLDivElement) {
      sendMessage(inputElement.innerText);
    }
    clearInput();
  };
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const shouldAutoScroll = useRef<boolean>(true);
  const checkShouldAutoScroll = () => {
    const container = messagesContainerRef.current;
    if (!container) return;
    const {
      scrollTop,
      scrollHeight,
      clientHeight
    } = container;
    shouldAutoScroll.current = scrollHeight - scrollTop - clientHeight < 100;
  };
  const scrollToBottomIfNeeded = () => {
    if (shouldAutoScroll.current && messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  };
  useEffect(() => {
    const container = messagesContainerRef.current;
    if (!container) return;
    container.addEventListener("scroll", checkShouldAutoScroll);
    return () => {
      container.removeEventListener("scroll", checkShouldAutoScroll);
    };
  }, []);
  useEffect(() => {
    const container = messagesContainerRef.current;
    if (!container) return;
    const observer = new MutationObserver(() => {
      scrollToBottomIfNeeded();
    });
    observer.observe(container, {
      childList: true,
      subtree: true,
      characterData: true
    });
    return () => observer.disconnect();
  }, []);
  useEffect(() => {
    scrollToBottomIfNeeded();
  }, [messages]);
  const messageGroups = groupMessagesByDate(messages);
  const isLastMessageOfAgent = messages[messages.length - 1]?.message_type === "agent";
  return <div className="flex flex-col mx-auto h-full overflow-hidden w-full" data-sentry-component="Chat" data-sentry-source-file="CopilotChat.tsx">
      <div ref={messagesContainerRef} className="flex-1 overflow-y-auto p-4 space-y-3">
        {messages.length === 0 && <NoMessagesState />}
        {messageGroups.map((group, groupIndex) => <div key={group.date} className="space-y-3">
            <div className="flex items-center justify-center my-4">
              <div className="flex items-center w-full">
                <div className="h-px bg-gray-300 dark:bg-gray-600 flex-grow"></div>
                <div className="text-sm text-gray-600 dark:text-gray-300 mx-3">
                  {group.date}
                </div>
                <div className="h-px bg-gray-300 dark:bg-gray-600 flex-grow"></div>
              </div>
            </div>
            {group.messages.map(msg => <div key={msg.id}>
                {msg.message_type === "user" ? <UserMessage msg={msg} /> : <AgentMessage msg={msg} inProgress={msg.status === "pending" || msg.status === "in_progress"} />}
              </div>)}
            {lastIncompleteUserMessage && !isLastMessageOfAgent && <AgentMessage msg={dummyAgentMessage} inProgress={true} />}
            {groupIndex === messageGroups.length - 1 && <ThinkingChain messages={messages} />}
          </div>)}
      </div>
      <div className="p-3 flex items-center">
        <ChatInputBox currentlySending={currentlySending} lastIncompleteUserMessage={lastIncompleteUserMessage} setInputRef={setInputRef} sendMessage={sendMessage} data-sentry-element="ChatInputBox" data-sentry-source-file="CopilotChat.tsx" />
        <ChatSendButton currentlySending={currentlySending} lastIncompleteUserMessage={lastIncompleteUserMessage} handleSendMessage={handleSendMessageFromButton} data-sentry-element="ChatSendButton" data-sentry-source-file="CopilotChat.tsx" />
      </div>
      <Select onValueChange={routerConfig.setRouter} defaultValue={routerConfig.router} data-sentry-element="Select" data-sentry-source-file="CopilotChat.tsx">
        <SelectTrigger data-sentry-element="SelectTrigger" data-sentry-source-file="CopilotChat.tsx">
          <SelectValue className="max-w-2" placeholder="Select a workflow" defaultValue={routerConfig.router} data-sentry-element="SelectValue" data-sentry-source-file="CopilotChat.tsx" />
        </SelectTrigger>
        <SelectContent className="max-w-10" data-sentry-element="SelectContent" data-sentry-source-file="CopilotChat.tsx">
          {routerConfig.routers.map(router => <SelectItem key={router} value={router}>
              {router}
            </SelectItem>)}
        </SelectContent>
      </Select>
    </div>;
};