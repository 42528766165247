export const UpArrow = () => {
  return <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="UpArrow" data-sentry-source-file="ButtonArrows.tsx">
      <rect width="36" height="36" rx="18" transform="matrix(1 0 0 -1 0 36)" fill="#00BDC8" data-sentry-element="rect" data-sentry-source-file="ButtonArrows.tsx" />
      <path d="M18.0003 23.8335V12.1668M18.0003 12.1668L23.8337 18.0002M18.0003 12.1668L12.167 18.0002" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="ButtonArrows.tsx" />
    </svg>;
};
export const RightArrow = () => {
  return <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="RightArrow" data-sentry-source-file="ButtonArrows.tsx">
      <rect width="36" height="36" rx="18" transform="matrix(1 0 0 -1 0 36)" fill="#00BDC8" data-sentry-element="rect" data-sentry-source-file="ButtonArrows.tsx" />
      <path d="M12.167 18.0002H23.8337M23.8337 18.0002L18.0003 23.8335M23.8337 18.0002L18.0003 12.1668" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="ButtonArrows.tsx" />
    </svg>;
};
export const StopMessage = () => {
  return <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="StopMessage" data-sentry-source-file="ButtonArrows.tsx">
      <rect width="36" height="36" rx="18" transform="matrix(1 0 0 -1 0 36)" fill="#00BDC8" data-sentry-element="rect" data-sentry-source-file="ButtonArrows.tsx" />
      <g clip-path="url(#clip0_669_6116)" data-sentry-element="g" data-sentry-source-file="ButtonArrows.tsx">
        <path d="M20.5003 20.5002L15.5003 15.5002M15.5003 20.5002L20.5003 15.5002M10.1553 14.0618C9.84274 14.3743 9.66709 14.7982 9.66699 15.2402V20.7602C9.66709 21.2022 9.84274 21.626 10.1553 21.9385L14.062 25.8452C14.3745 26.1577 14.7983 26.3334 15.2403 26.3335H20.7603C21.2023 26.3334 21.6262 26.1577 21.9387 25.8452L25.8453 21.9385C26.1579 21.626 26.3336 21.2022 26.3337 20.7602V15.2402C26.3336 14.7982 26.1579 14.3743 25.8453 14.0618L21.9387 10.1552C21.6262 9.84258 21.2023 9.66692 20.7603 9.66683H15.2403C14.7983 9.66692 14.3745 9.84258 14.062 10.1552L10.1553 14.0618Z" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="ButtonArrows.tsx" />
      </g>
      <defs data-sentry-element="defs" data-sentry-source-file="ButtonArrows.tsx">
        <clipPath id="clip0_669_6116" data-sentry-element="clipPath" data-sentry-source-file="ButtonArrows.tsx">
          <rect width="20" height="20" fill="white" transform="matrix(1 0 0 -1 8 28)" data-sentry-element="rect" data-sentry-source-file="ButtonArrows.tsx" />
        </clipPath>
      </defs>
    </svg>;
};