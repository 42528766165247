import { Textarea } from "@/components/ui/textarea";
export const ChatInputBox = ({
  currentlySending,
  lastIncompleteUserMessage,
  setInputRef,
  sendMessage
}: {
  currentlySending: boolean;
  lastIncompleteUserMessage: boolean;
  setInputRef: (element: HTMLTextAreaElement | HTMLDivElement | null) => void;
  sendMessage: (message: string) => void;
}) => {
  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      sendMessage(e.currentTarget.value);
      e.currentTarget.value = "";
    }
  };
  const handleKeyDownForEditableDiv = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      sendMessage(e.currentTarget.innerText);
      e.currentTarget.innerText = "";
    }
  };

  // if (!currentlySending) {
  //   return (
  //     <div
  //       contentEditable
  //       className="flex-grow p-2 border rounded-lg focus:outline-none bg-[#E8F8F9] shadow-[inset_0_2px_4px_rgba(0,0,0,0.1)]  w-full resize-none overflow-y-auto focus-visible:outline-none focus-visible:ring-secondary/5 max-h-[300px] min-h-[100px]"
  //       onKeyDown={handleKeyDownForEditableDiv}
  //       ref={setInputRef}
  //     >
  //       {/* placeholder */}
  //       {/* <span className="text-gray-500">Ask a question</span> */}
  //     </div>
  //   );
  // }
  return <Textarea autoFocus className="flex-grow p-2 border rounded-lg focus:outline-none bg-[#E8F8F9] shadow-[inset_0_2px_4px_rgba(0,0,0,0.1)]  w-full resize-none overflow-y-auto focus-visible:outline-none focus-visible:ring-secondary/5 " placeholder="Ask a question" onKeyDown={handleKeyDown} disabled={lastIncompleteUserMessage || currentlySending} ref={setInputRef} autoComplete="off" rows={5} data-sentry-element="Textarea" data-sentry-component="ChatInputBox" data-sentry-source-file="ChatInputBox.tsx" />;
};