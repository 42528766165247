import { CopilotMessage } from "@/server/providers/copilot";
import Image from "next/image";
export const UserMessage = ({
  msg
}: {
  msg: CopilotMessage;
}) => {
  return <div className="w-full flex flex-row justify-end items-start" data-sentry-component="UserMessage" data-sentry-source-file="UserMessage.tsx">
      <div className="w-1/2 bg-white rounded-lg p-4 shadow-md border border-gray-200">
        <span className="text-sm">{msg.content}</span>
      </div>
      <Image src="/user-copilot.svg" alt="User Avatar" width={32} height={32} className="mx-3" data-sentry-element="Image" data-sentry-source-file="UserMessage.tsx" />
    </div>;
};