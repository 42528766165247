import { Collapsible, CollapsibleTrigger, CollapsibleContent } from "@/components/ui/collapsible";
import { cn } from "@/lib/utils";
import { CopilotMessage, MessageMetadata } from "@/server/providers/copilot";
import { useChat } from "./ChatProvider";
import { useState } from "react";
export default function SuggestedPrompts({
  followUpPrompts,
  msgStatus
}: {
  msgStatus: CopilotMessage["status"];
  followUpPrompts: MessageMetadata["follow_up_prompts"];
}) {
  const {
    sendMessage
  } = useChat();
  const [open, setOpen] = useState(false);
  const suggestedPrompts = followUpPrompts?.prompts ?? [];
  return <Collapsible onOpenChange={setOpen} data-sentry-element="Collapsible" data-sentry-component="SuggestedPrompts" data-sentry-source-file="SuggestedPrompts.tsx">
      <CollapsibleTrigger className={cn(msgStatus !== "completed" || suggestedPrompts.length === 0 ? "hidden" : "")} data-sentry-element="CollapsibleTrigger" data-sentry-source-file="SuggestedPrompts.tsx">
        <div className="text-xs text-primary">
          {open ? "Hide Suggested Prompts" : "Show Suggested Prompts"}
        </div>
      </CollapsibleTrigger>
      <CollapsibleContent data-sentry-element="CollapsibleContent" data-sentry-source-file="SuggestedPrompts.tsx">
        <div className="flex flex-row gap-2 max-w-22 flex-wrap">
          {suggestedPrompts.map((prompt, index) => <div key={index} className="p-1 border rounded-md text-xs text-secondary bg-secondary/10 cursor-pointer" onClick={() => {
          sendMessage(prompt.prompt);
        }}>
              {prompt.prompt}
            </div>)}
        </div>
      </CollapsibleContent>
    </Collapsible>;
}