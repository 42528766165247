import { Button } from "@/components/ui/button";
import { Send } from "lucide-react";
import { UpArrow } from "./ButtonArrows";
export const ChatSendButton = ({
  lastIncompleteUserMessage,
  currentlySending,
  handleSendMessage
}: {
  lastIncompleteUserMessage: boolean;
  currentlySending: boolean;
  handleSendMessage: () => void;
}) => {
  return <Button type="submit" variant="ghost" disabled={lastIncompleteUserMessage || currentlySending} onClick={handleSendMessage} data-sentry-element="Button" data-sentry-component="ChatSendButton" data-sentry-source-file="ChatSendButton.tsx">
      <UpArrow data-sentry-element="UpArrow" data-sentry-source-file="ChatSendButton.tsx" />
    </Button>;
};