import { MessageMetadata } from "@/server/providers/copilot";
import Image from "next/image";
export const AgentMessageChartingImage = ({
  metadata,
  src
}: {
  metadata: MessageMetadata;
  src: string;
}) => {
  let image = null;
  const plot = metadata?.apps?.plots?.find(x => x?.plot_input?.id === src);
  if (!plot) {
    // check event study
    const eventStudy = metadata?.apps?.event_study;
    if (eventStudy?.id === src) {
      image = eventStudy.plot_image;
    }
  } else {
    image = plot.plot_image;
  }
  if (!image) {
    return null;
  }
  return <Image src={`data:image/png;base64,${image}`}
  // Todo: add alt
  alt={"alt"} className="max-w-full h-auto" width={500} height={500} unoptimized data-sentry-element="Image" data-sentry-component="AgentMessageChartingImage" data-sentry-source-file="AgentMessageChartingImage.tsx" />;
};